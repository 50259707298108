import styled from 'styled-components'

import FluffyButton from '@gotamedia/fluffy/Button'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const LinkWrapper = styled.div`
    
`

const Anchor = styled.a`

`

const ButtonLink = styled(FluffyButton)`
    width: 100%;
    margin: 10px 0;
`

export {
    Wrapper,
    LinkWrapper,
    Anchor,
    ButtonLink
}