import { useLoaderData } from '@remix-run/react'

import * as Styled from './style'
import type * as Types from './types'
import type { CompanyPayload } from '~/modules/marketGuide/types'

const CompanyContent: Types.CompanyContentComponent = () => {
    const { payload: { presentation, introduction } } = useLoaderData<{ payload: CompanyPayload }>()

    return (
        <>
            <Styled.Content dangerouslySetInnerHTML={{__html: introduction}} />
            <Styled.Content dangerouslySetInnerHTML={{__html: presentation}} />
        </>
    )
}

export default CompanyContent