import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Embedds = styled.div`
    iframe {
        width: 100% !important;
    }
`

export {
    Wrapper,
    Embedds
}