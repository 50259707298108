import { useLoaderData } from '@remix-run/react'

import * as Styled from './style'
import type * as Types from './types'
import type { JobPayload } from '~/modules/jobs/types'

const JobLogo: Types.JobLogoComponent= () => {
    const {
        payload: {
            title,
            logoUrl,
            presentationImageUrl
        }
    } = useLoaderData<{ payload: JobPayload }>()
    
    return (
        <>
            <Styled.Wrapper>
                {
                    logoUrl ? (
                        <Styled.Logo
                            src={logoUrl}
                            alt={`${title} logo`}
                        />
                    ) : (
                        null
                    )
                }
            </Styled.Wrapper>

            {
                presentationImageUrl ? (
                    <Styled.Cover
                        src={presentationImageUrl}
                        alt={`${title} cover`}
                    />
                ) : (
                    null
                )
            }
        </>
    )
}

export default JobLogo