import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d5d5d5;
`

const Title = styled.h1`
    font-weight: 600;
    font-size: 25px;
    color: ${({ theme }) => theme.colors.brand};
`

const Text = styled.p`

`

const Link = styled.a`
    color: ${({ theme }) => theme.colors.brand};
    fill: ${({ theme }) => theme.colors.brand};
    display: flex;
    margin: 0 auto 0 0;
    &:hover {
        text-decoration: underline;
    }

    span {
        margin: auto 10px auto 0;
    }
`


export {
    Wrapper,
    Title,
    Text,
    Link
}