import { useLoaderData } from '@remix-run/react'

import FluffyMaps from '@gotamedia/fluffy/Maps'

import * as Styled from './style'
import type * as Types from './types'

import type { CompanyPayload } from '~/modules/marketGuide/types'

const CompanyLinks: Types.CompanyLinksComponent = () => {
    const { payload: {
        mapActive,
        longitude,
        latitude
    } } = useLoaderData<{ payload: CompanyPayload }>()

    const position = [
        latitude,
        longitude
    ]

    return (
        mapActive ? (
            <Styled.Wrapper>
                <FluffyMaps.Map
                    height={300}
                    defaultZoom={15}
                    defaultCenter={position as FluffyMaps.Point}
                >
                    <FluffyMaps.Marker anchor={position as FluffyMaps.Point} />
                    <FluffyMaps.ZoomControl />
                </FluffyMaps.Map>
            </Styled.Wrapper>
        ) : (
            null
        )
    )
}

export default CompanyLinks