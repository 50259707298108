import { useEffect, useState } from 'react'
import { useLoaderData,  } from '@remix-run/react'

import * as Styled from './style'
import type * as Types from './types'
import type { JobPayload } from '~/modules/jobs/types'

const JobImages: Types.JobImagesComponent = () => {
    const {
        payload: {
            galleryImageUrls
        }
    } = useLoaderData<{ payload: JobPayload }>()

    const [index, setIndext] = useState(0)
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    const images = galleryImageUrls && galleryImageUrls.length ? (
        galleryImageUrls.map(image => {
            return (
                <Styled.Image
                    key={image}
                    alt={image}
                    src={image}
                />
            )
        })
    ) : (
        null
    )
    
    return (
        isMounted && images ? (
            <Styled.Wrapper>
                <Styled.ImageGallery
                    index={index}
                    onChange={setIndext}
                >
                    <Styled.ImageGallery.Slides>
                        {images}
                    </Styled.ImageGallery.Slides>

                    <Styled.ImageGallery.Preview>
                        {images}
                    </Styled.ImageGallery.Preview>

                    <Styled.ImageGallery.Navigation />
                    {/* Disabled temporarily due to it breaks in some browsers when the App is iframed */}
                    {/* <Styled.ImageGallery.Fullscreen /> */}
                    <Styled.ImageGallery.SlidesCount />
                </Styled.ImageGallery>
            </Styled.Wrapper>
        ) : (
            null
        )
    )
}

export default JobImages