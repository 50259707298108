import styled from 'styled-components'

const BackButton = styled.span`
    font-family: 'Sanomat Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 18px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.link};
`

export {
    BackButton
}
