import { useLoaderData } from '@remix-run/react'

import JobImages from '../JobImages'
import ApplyButton from '../ApplyButton'

import * as Styled from './style'
import type * as Types from './types'
import type { JobPayload } from '~/modules/jobs/types'

const JobContent: Types.JobContentComponent= () => {
    const {
        payload: {
            title,
            uuid,
            introduction,
            presentation,
            buttonLink
        }
    } = useLoaderData<{ payload: JobPayload }>()

    return (
        <Styled.Wrapper>
            <Styled.Title>
                {title}
            </Styled.Title>

            <Styled.Content dangerouslySetInnerHTML={{__html: introduction}}/>

            <Styled.Info dangerouslySetInnerHTML={{__html: presentation}}/>

            <JobImages />

            <ApplyButton
                uuid={uuid}
                path={buttonLink}
            />
        </Styled.Wrapper>
    )
}

export default JobContent