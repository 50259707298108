import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const InnerWrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;

    ${themeHelpers.isSmallDevice(css`
        width: unset;
        margin: 10px;
    `)}
`

const RightNowHeadline = styled.p`
    font-size: 17px;
    font-weight: 700;
    margin: 20px 0 0 0;

    ${themeHelpers.isSmallDevice(css`
        margin-top: 5px;
    `)}
`

const Content = styled.div`
    display: flex;
    flex: 1;
    margin-bottom: 20px;
    padding-bottom: 20px;

    ${themeHelpers.isSmallDevice(css`
        flex-direction: column;
    `)}
`

const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    padding: 20px 20px 20px 0;
    margin-right: 20px;
    border-right: 2px solid #d5d5d5;

    ${themeHelpers.isSmallDevice(css`
        padding: 0;
        margin-right: 0;
        margin-bottom: 10px;
        border-right: 0;
        border-bottom: 2px solid #d5d5d5;
    `)}
`

const SidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 20px 20px 0;

    ${themeHelpers.isSmallDevice(css`
        padding: 0;
    `)}
`

export {
    Wrapper,
    InnerWrapper,
    RightNowHeadline,
    Content,
    InnerContent,
    SidebarContent
}