import styled, { css } from 'styled-components'
import { themeHelpers } from '@gotamedia/fluffy/theme'

import SocialMediaShareButtons from '~/components/SocialMediaShare'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const BackLinkContainer = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
`
const Container = styled.div`
    display: flex;
    margin: auto;
    padding-bottom: 30px;
    flex-direction: column;
`

const InnerWrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;

    ${themeHelpers.isSmallDevice(css`
        width: unset;
        margin: 10px;
    `)}
`
const ImageWrapper = styled.div`
    display: flex;
    min-width: 350px;
    background-color: #FFF;
    border-radius: 0px;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 0 0px 30px -5px #cecece;
    z-index: 1;

    ${themeHelpers.isSmallDevice(css`
        width: 350px;
        height: 600px;
        flex-direction: column;
    `)}
`

const Image = styled.img`
    margin: auto;
    height: 100%;
    object-fit: contain;
`

const SocialMediaShare = styled(SocialMediaShareButtons)`
    z-index: 999;
    margin: 0 auto;
`

export {
    BackLinkContainer,
    Container,
    Wrapper,
    InnerWrapper,
    ImageWrapper,
    Image,
    SocialMediaShare
}