import { AvailableApps } from '~/config/apps'

import throwAppError from '~/utils/throwAppError'

import useRouteData from '~/hooks/useRouteData'

import CompanyPage from '~/components/MarketGuide/pages/CompanyPage'
import JobPage from '~/components/Jobs/pages/JobPage'
import FamilyPage from '~/components/Family/pages/FamilyPage'
import ChurchEventPage from '~/components/ChurchEvent/pages/ChurchEventPage'

import type * as Types from './types'
import type { RootLoaderData } from '~/types'

const ItemPage: Types.ItemPageComponent = () => {
    const { app } = useRouteData<RootLoaderData>('root')

    if (!app || !app.id) {
        throwAppError()
    }

    switch(app.id) {
        case AvailableApps.MarketGuide:
            return (
                <CompanyPage />
            )

        case AvailableApps.Jobs:
            return (
                <JobPage />
            )

        case AvailableApps.Family:
            return (
                <FamilyPage />
            )


        case AvailableApps.ChurchEvent:
            return (
                <ChurchEventPage />
            )

        default:
            throwAppError()
            
            return (
                null
            )
    }
}

export default ItemPage