import { useLoaderData } from '@remix-run/react'

import useEmbeddedRect from '~/hooks/useEmbeddedRect'

import SocialMediaShare from '~/components/SocialMediaShare'

import CompanyLogo from './components/CompanyLogo'
import CompanyInfo from './components/CompanyInfo'
import CompanyOpeningHours from './components/CompanyOpeningHours'
import CompanyEmbeds from './components/CompanyEmbeds'
import CompanyContent from './components/CompanyContent'
import CompanyLocation from './components/CompanyLocation'
import CompanyLinks from './components/CompanyLinks'

import * as Styled from './style'
import type * as Types from './types'
import type { CompanyPayload } from '~/modules/marketGuide/types'
import BackLink from '~/components/BackLink/BackLink'

const CompanyPage: Types.CompanyPageComponent = () => {
    const { rectRefSetter } =  useEmbeddedRect()

    const { payload } = useLoaderData<{ payload: CompanyPayload }>()

    return (
        <Styled.Wrapper ref={rectRefSetter}>
            {Object.keys(payload).length > 0 ? (
                <Styled.InnerWrapper>
                    <BackLink />
                    <CompanyLogo />
                    <Styled.Content>
                        <Styled.InnerContent>
                            <CompanyInfo />
                            <CompanyOpeningHours />
                            <CompanyLinks />
                            <CompanyContent />
                        </Styled.InnerContent>

                        <Styled.SidebarContent>
                            <CompanyLocation />
                            <CompanyEmbeds />
                            <SocialMediaShare />
                        </Styled.SidebarContent>
                    </Styled.Content>
                </Styled.InnerWrapper>
            ) : null}
        </Styled.Wrapper>
    )
}

export default CompanyPage