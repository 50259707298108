import { useCallback } from 'react'

import {
    useLocation,
    useNavigate
} from '@remix-run/react'

import * as Styled from './style'
import type * as Types from './types'

const BackLink: Types.BackLinkComponent = () => {
    const { state } = useLocation()
    const navigate = useNavigate()

    const handleOnClick = useCallback(() => {
        let to: string | number = '/'

        if (state?.intirnalNavigation) {
            to = -1
        }

        // @ts-ignore
        navigate(to)
    }, [state, navigate])

    return (
        <Styled.BackButton onClick={handleOnClick}> 
            {"←  Översikt"}
        </Styled.BackButton>
    )
}

export default BackLink