import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const LinkWrapper = styled.div`
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
`

const Embedds = styled.div`
    iframe {
        width: 100% !important;
    }
`

export {
    Wrapper,
    LinkWrapper,
    Embedds
}