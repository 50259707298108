import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'
import Icon from '@gotamedia/fluffy/Icon'

const Container = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: 20px auto;

    ${themeHelpers.isSmallDevice(css`
        width: 95%;
    `)}
`

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 20px;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
`
const Image = styled.img`
    width: 660px;
    object-position: center;
`

const Headline = styled.h2`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 16px 0;
`
const Date = styled.span`

`
const InfoContainer = styled.div`

`
const Description = styled.p`
`

const DetailContainer = styled.div`
    display: flex;
    flex-direction: row;
`
const StyledIcon = styled(Icon)`
    margin-right: 8px;
`

const DetailInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
`

const ItemWrapper = styled.div`
    text-align: left;
    max-width: 660px;
    width: 100%;
`

const InnerWrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    position: relative;
    align-items: center;

    ${themeHelpers.isSmallDevice(css`
        width: 95%;
    `)}
`

export {
    ImageWrapper,
    Image,
    Headline,
    Date,
    ItemWrapper,
    InfoContainer,
    DetailContainer,
    DetailInfoContainer,
    Description,
    Wrapper,
    Container,
    StyledIcon,
    InnerWrapper
}
