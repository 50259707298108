import { useLoaderData } from '@remix-run/react';
import type { SyntheticEvent } from 'react';
import { useCallback, useState } from 'react';

import useEmbeddedRect from '~/hooks/useEmbeddedRect';

import { Icons, IconSizes, IconVariants } from '@gotamedia/fluffy/Icon';
import BackLink from '~/components/BackLink';
import type { ChurchEventAdsPayload } from '~/modules/churchevent/types';
import { isClient } from '~/utils/helpers';
import * as Styled from './style';
import type * as Types from './types';

const ChurchEventPage: Types.ChurchEventPageComponent = () => {
    const { rectRefSetter } =  useEmbeddedRect()

    const { payload } = useLoaderData<Types.SearchResponse>()

    const [items] = useState<ChurchEventAdsPayload['data_list']>(payload?.data_list || [])


    const replaceImage =  useCallback((error: SyntheticEvent<HTMLImageElement>) => {
        (error.target as HTMLImageElement).src = "/images/Church.svg"
    }, [])

    const getText = (text: string) => {
        if(isClient) {
            let txt = document.createElement("textarea");
            txt.innerHTML = text;
            return txt.value;
        }
        else {
            return text
        }

    }

    const BrokenImage = "/images/Church.svg"
    const brokenImageLinks = [
        "https://cargodisplayads.com/gota_event/api/v1_1/adImage/default/120",
        "https://cargodisplayads.com/gota_event_test/api/v2/image/default/120",
        "https://cargodisplayads.com/gota_event/api/v2/image/default/120"
    ]

    return (
        <>
            <Styled.Container>
                <BackLink />
            </Styled.Container>

            <Styled.Wrapper ref={rectRefSetter}>
                {items.map((item) => {
                    return (
                        <Styled.InnerWrapper key={item.uid}>
                            <Styled.ItemWrapper>

                                <Styled.ImageWrapper >
                                    <Styled.Image
                                        src={item.main_image && !brokenImageLinks.includes(item.main_image) ? item.main_image : BrokenImage}
                                        onError={replaceImage}
                                        alt={getText(item.body.replace(/<[^>]*>?/gm, ''))}
                                    />
                                </Styled.ImageWrapper>

                                <Styled.Headline>
                                    {item.title}
                                </Styled.Headline>
                                <Styled.Description>
                                    {getText(item.body.replace(/<[^>]*>?/gm, ''))}
                                </Styled.Description>
                                <Styled.DetailInfoContainer>
                                    <Styled.StyledIcon
                                        icon={Icons.Clock}
                                        size={IconSizes.Small}
                                        color={"#8A8A8D"}
                                        variant={IconVariants.Solid}
                                    />
                                    {new Date(item.event_start_time.replace(/-/g, "/"))
                                        .toLocaleString(
                                            undefined,
                                            {month: "short", day: "numeric", hour: "2-digit", minute: "2-digit"}
                                        )
                                    }
                                </Styled.DetailInfoContainer>
                                <Styled.DetailInfoContainer>
                                    <Styled.StyledIcon
                                        icon={Icons.MapPin}
                                        color={"#8A8A8D"}
                                        size={IconSizes.Small}
                                        variant={IconVariants.Solid}
                                    />
                                    <span>{item.placement }{" - "}{item.subject_location}</span>
                                </Styled.DetailInfoContainer>

                            </Styled.ItemWrapper>
                        </Styled.InnerWrapper>
                    )
                })}
            </Styled.Wrapper>
        </>
    )
}

export default ChurchEventPage
