import styled from 'styled-components'

import ImageGalleryComopnent from '@gotamedia/fluffy/ImageGallery'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 15px 0;
    margin: 15px 0;
    flex-direction: column;
    border-top: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
`

const ImageGallery = styled(ImageGalleryComopnent)`
    width: 100%;
    height: 400px;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: contain;
`

export {
    Wrapper,
    ImageGallery,
    Image
}