import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const InnerWrapper = styled.div`
    display: flex;
    
    button {
        margin: 10px;
        overflow: hidden;
        border-radius: 50%;

        &:first-child {
            margin-left: 0;
        }

        svg {
            width: 40px;
            height: 40px;
        }
    }
`

const Title = styled.p`
    font-weight: 700;
    padding-top: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    border-top: 1px solid #d5d5d5;
`

export {
    Wrapper,
    InnerWrapper,
    Title
}