import DateUtility from '@gotamedia/fluffy/date'

const getWeekDayNames = () => {
    const now = new Date()

    const weekDays: Record<string, string> = {}

    const start = DateUtility.startOfWeek(now, { locale: DateUtility.locales.sv })
    const end = DateUtility.endOfWeek(now, { locale: DateUtility.locales.sv })

    DateUtility.eachDayOfInterval({ start, end }).forEach(day => {
        const swedishWeekDay = DateUtility.format(day, "EEEE", { locale: DateUtility.locales.sv })
        const englishWeekDay = DateUtility.format(day, "EEEE", { locale: DateUtility.locales.enUS })

        weekDays[englishWeekDay.toLowerCase()] = swedishWeekDay.toLowerCase()
    })

    return weekDays
}

export default getWeekDayNames