import { useLoaderData } from '@remix-run/react'

import { ItemType } from '~/services/resolvers/types'

import Celebration from './components/Celebration'
import Memorial from './components/Memorial'

import * as Styled from './style'
import type * as Types from './types'

const FamilyPage: Types.FamilyPageComponent = () => {
    const { _type } = useLoaderData()

    const content = () => {
        switch(_type) {
            case ItemType.BirthdayAd:
                return (
                    <Celebration />
                )

            case ItemType.MemorialAd:
                return (
                    <Memorial />
                )

            default:
                return (
                    null
                )
        }
    }

    return (
        <Styled.Wrapper>
            {content()}
        </Styled.Wrapper>
    )
}

export default FamilyPage