import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'

const Wrapper = styled.div`
    display: flex;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #d5d5d5;

    ${themeHelpers.isSmallDevice(css`
        padding: 10px 0;
        flex-direction: column;
    `)}
`

const Logo = styled.img`
    max-width: 200px;
    margin-right: 20px;

    ${themeHelpers.isSmallDevice(css`
        max-width: 200px;
        margin: auto;
    `)}
`

const Cover = styled.img`
    margin: auto;
    height: auto;
    width: 100%;
    margin-bottom: 20px;
`

export {
    Wrapper,
    Logo,
    Cover
}