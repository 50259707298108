import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'

const Wrapper = styled.div<{ $hasLogo: boolean }>`
    display: flex;
    cursor: pointer;
    padding: 20px 0;
    
    ${({ $hasLogo }) => $hasLogo && css`
        margin-bottom: 20px;
        border-bottom: 1px solid #d5d5d5;
    `}

    ${themeHelpers.isSmallDevice(css`
        padding: 10px 0;
        flex-direction: column;
    `)}
`

const Logo = styled.img`
    max-width: 200px;
    margin-right: 20px;

    ${themeHelpers.isSmallDevice(css`
        max-width: 200px;
        margin: auto;
    `)}
`

const CoverWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 250px;
    display: flex;
    margin-bottom: 20px;
`

const CoverBackground = styled.div<{ $url: string }>`
    position: absolute;
    filter: blur(4px);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-image: url(${({ $url }) => $url});
    background-repeat: no-repeat;
    background-size: cover;
`

const Cover = styled.img`
    z-index: 1;
    margin: auto;
    width: auto;
    max-height: 200px;
    border-radius: 5px;
`

export {
    Wrapper,
    Logo,
    CoverWrapper,
    CoverBackground,
    Cover
}