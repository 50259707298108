import { useLoaderData } from '@remix-run/react'
import { useCallback } from 'react'

import getValidUrl from '~/utils/getValidUrl'

import * as Styled from './style'
import type * as Types from './types'
import type { CompanyPayload } from '~/modules/marketGuide/types'

const CompanyLogo: Types.CompanyLogoComponent = () => {
    const { payload } = useLoaderData<{ payload: CompanyPayload }>()

    const {
        websiteUrl,
        logoUrl,
        name,
        presentationImageUrl
    } = payload

    const handleOpenWebsite = useCallback(() => {
        window.open(getValidUrl(websiteUrl), '_blank')
    }, [websiteUrl])

    return (
        <>
            <Styled.Wrapper
                $hasLogo={logoUrl ? true : false}
                onClick={handleOpenWebsite}
            >
                {
                    logoUrl ? (
                        <Styled.Logo
                            src={logoUrl}
                            alt={`${name} logo`}
                        />
                    ) : (
                        null
                    )
                }
            </Styled.Wrapper>

            {
                presentationImageUrl ? (
                    <Styled.CoverWrapper>
                        <Styled.CoverBackground $url={presentationImageUrl} />

                        <Styled.Cover
                            src={presentationImageUrl}
                            alt={`${name} cover`}
                        />
                    </Styled.CoverWrapper>
                ) : (
                    null
                )
            }
        </>
    )
}

export default CompanyLogo