import { useLoaderData } from '@remix-run/react'

import * as Styled from './style'
import type * as Types from './types'
import type { CompanyPayload } from '~/modules/marketGuide/types'

const CompanyLinks: Types.CompanyLinksComponent = () => {
    const {
        payload: {
            links
        }
    } = useLoaderData<{ payload: CompanyPayload }>()

    return (
        <Styled.Wrapper>
            {
                links.map((item, idx) => {
                    return (
                        <Styled.LinkWrapper key={idx}>
                            <Styled.Anchor
                                href={item.url}
                                target={'_blank'}
                            >
                                <Styled.ButtonLink>
                                    {item.text}
                                </Styled.ButtonLink>
                            </Styled.Anchor>
                        </Styled.LinkWrapper>
                    )
                })
            }
        </Styled.Wrapper>
    )
}

export default CompanyLinks