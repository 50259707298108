import getValidUrl from '~/utils/getValidUrl'

import Icon, {
    Icons,
    IconSizes
} from '@gotamedia/fluffy/Icon'

import * as Styled from './style'

const getButtonLink = (value: string) => {
    const emailRegx = /\S+@\S+\.\S+/

    if (emailRegx.test(value)) {
        return `mailto:${value}`
    } else {
        return getValidUrl(value)
    }
}

const ApplyButton = (props: { path: string, className?: string }) => {
    const {
        path,
        className
    } = props

    const link = getButtonLink(path)

    return (
        path ? (
            <Styled.Link
                target={'_blank'}
                href={link}
                className={className}
            >
                <Styled.ApplyButton>
                    <Icon
                        icon={Icons.Link}
                        size={IconSizes.Small}
                    />

                    {'Ansök nu'}
                </Styled.ApplyButton>
            </Styled.Link>
        ) : (
            null
        )
    )
}

export default ApplyButton