import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    background: white;
`

export {
    Wrapper
}