import {
    useRef,
    useCallback,
    useEffect,
    useState
} from 'react'

import { useLoaderData } from '@remix-run/react'
import ReactCanvasConfetti from 'react-canvas-confetti'
import { useIsomorphicLayoutEffect } from '@gotamedia/fluffy'

import useEmbeddedRect from '~/hooks/useEmbeddedRect'

import FamilyAdPage from '~/components/Family/pages/FamilyAdPage'

import * as Styled from './style'
import type * as Types from './types'
import type { FamilyAdsPayload } from '~/modules/family/types'

const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100
}

const Celebration: Types.CelebrationComponent = () => {
    const [isReady, setIsReady] = useState(false)

    const { rectRefSetter, trigger, revalidate } =  useEmbeddedRect()

    useIsomorphicLayoutEffect(() => {
        trigger({
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
            x: 0,
            y: 0
        })

        setIsReady(true)
    }, [trigger])

    useEffect(() => {
        if (isReady) {
            revalidate({ force: true })
        }
    }, [revalidate, isReady])

    const refAnimationInstance = useRef<any>(null)

    const { payload } = useLoaderData<{ payload: FamilyAdsPayload}>()

    const adItem = Array.isArray(payload.ads) && payload.ads[0] ? payload.ads[0] : null


    const getInstance = useCallback((instance: any) => {
        refAnimationInstance.current = instance
    }, [])

    const makeShot = useCallback((particleRatio: any, opts: any) => {
        if (refAnimationInstance.current) {
            refAnimationInstance.current({
                ...opts,
                origin: { y: 0.7 },
                particleCount: Math.floor(200 * particleRatio)
            })
        }
    }, [])

    const fire = useCallback(() => {
        makeShot(0.25, {
            spread: 26,
            startVelocity: 55
        })

        makeShot(0.2, {
            spread: 60
        })

        makeShot(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        })

        makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        })

        makeShot(0.1, {
            spread: 120,
            startVelocity: 45
        })
    }, [makeShot])

    useEffect(() => {
        fire()
    }, [fire])
    
    return (
        <Styled.Wrapper ref={rectRefSetter}>
            <FamilyAdPage
                image={adItem?.main_image || ""}
                alt={adItem?.body || ""}
                onClick={fire}
            />

            <ReactCanvasConfetti
                refConfetti={getInstance}
                //@ts-ignore
                style={canvasStyles}
            />
        </Styled.Wrapper>
    )
}

export default Celebration