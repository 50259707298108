import BackLink from '~/components/BackLink'

import * as Styled from './style'
import type * as Types from './types'

const FamilyAdPage: Types.FamilyAdPageComponent = (props) => {
    const {
        image,
        alt,
        onClick
    } = props

    return (
        <>
            <Styled.BackLinkContainer>
                <BackLink />
            </Styled.BackLinkContainer>

            <Styled.Wrapper onClick={onClick}>
                <Styled.InnerWrapper>
                    <Styled.Container>
                        <Styled.ImageWrapper>
                            <Styled.Image
                                src={image}
                                alt={alt}
                            />
                        </Styled.ImageWrapper>
                        
                        <Styled.SocialMediaShare withHeader={false} />
                    </Styled.Container>
                </Styled.InnerWrapper>
            </Styled.Wrapper>
        </>
    )
}

export default FamilyAdPage