import { useLoaderData } from '@remix-run/react'

import Icon, {
    Icons,
    IconSizes
} from '@gotamedia/fluffy/Icon'

import getValidUrl from '~/utils/getValidUrl'
import { format as formatDate } from '~/utils/date'

import useViewSize from '~/hooks/useViewSize'

import * as Styled from './style'
import type * as Types from './types'
import type { JobPayload } from '~/modules/jobs/types'

const JobInfo: Types.JobInfoComponent= () => {
    const { payload: {
        uuid,
        companyName,
        placements,
        branches,
        employmentType,
        buttonLink,
        applicationDeadline,
        contactName,
        contactEmail,
        contactPhone,
        websiteUrl,
        remoteAllowed
    } } = useLoaderData<{ payload: JobPayload }>()

    const { isSmall } = useViewSize()

    const places = [
        remoteAllowed ? 'Distans' : null,
        ...placements
    ].filter(i => i)

    return (
        <Styled.Wrapper>
            {
                !isSmall() ? (
                    <Styled.ApplyButton
                        uuid={uuid}
                        path={buttonLink}
                    />
                ) : (
                    null
                )
            }

            <Styled.Headline>
                {'Företag'}
            </Styled.Headline>

            <Styled.Text>
                {companyName}
            </Styled.Text>
            
            <Styled.Headline>
                {'Placering'}
            </Styled.Headline>

            <Styled.Text>
                {places.join(', ')}
            </Styled.Text>

            <Styled.Headline>
                {'Bransch'}
            </Styled.Headline>

            <Styled.Text>
                {branches.join(', ')}
            </Styled.Text>

            <Styled.Headline>
                {'Anställningsform'}
            </Styled.Headline>

            <Styled.Text>
                {employmentType}
            </Styled.Text>

            <Styled.Headline>
                {'Sista ansökningsdag'}
            </Styled.Headline>

            <Styled.Text>
                {applicationDeadline ? formatDate(applicationDeadline) : ' - '}
            </Styled.Text>

            <Styled.Headline>
                {'Kontaktinformation'}
            </Styled.Headline>

            <Styled.Text>
                {contactName}
            </Styled.Text>

            {
                contactEmail ? (
                    <Styled.TextLink href={`mailto:${contactEmail}`}>
                        <Icon
                            icon={Icons.Envelope}
                            size={IconSizes.Tiny}
                        />

                        {contactEmail}
                    </Styled.TextLink>
                ) : (
                    null
                )
            }

            {
                contactPhone ? (
                    <Styled.TextLink href={`tel:${contactPhone}`}>
                        <Icon
                            icon={Icons.Phone}
                            size={IconSizes.Tiny}
                        />

                        {contactPhone}
                    </Styled.TextLink>
                ) : (
                    null
                )
            }

            {
                websiteUrl ? (
                    <Styled.TextLink href={getValidUrl(websiteUrl)} target={'_blank'}>
                        <Icon
                            icon={Icons.Link}
                            size={IconSizes.Tiny}
                        />

                        {'Hemsida'}
                    </Styled.TextLink>
                ) : (
                    null
                )
            }

            <Styled.Text>
                {'Vi undanber oss all kontakt från telefonsäljare.'}
            </Styled.Text>
        </Styled.Wrapper>
    )
}

export default JobInfo