import { useLoaderData } from '@remix-run/react'
import {
    CMPR,
    getVendorFromPlainHTML
} from '@gotamedia/cmp'

import { isClient } from '~/utils/helpers'

import * as Styled from './style'
import type * as Types from './types'
import type { CompanyPayload } from '~/modules/marketGuide/types'

const CompanyEmbeds: Types.CompanyEmbedsComponent = () => {
    const {
        payload: {
            embeds
        }
    } = useLoaderData<{ payload: CompanyPayload }>()

    return (
        <Styled.Wrapper>
            {
                isClient ? (
                    embeds.map((item, idx) => {
                        const vendor = getVendorFromPlainHTML(item.embedCode)

                        return (
                            vendor ? (
                                <Styled.LinkWrapper key={idx}>
                                    {
                                        isClient ? (
                                            <CMPR vendor={vendor}>
                                                <Styled.Embedds dangerouslySetInnerHTML={{__html: item.embedCode}} />
                                            </CMPR>
                                        ) : (
                                            null
                                        )
                                    }
                                </Styled.LinkWrapper>
                            ) : (
                                null
                            )
                        )
                    })
                ) : (
                    <div />
                )
            }
        </Styled.Wrapper>
    )
}

export default CompanyEmbeds