import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ $hasOpeningHours: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ $hasOpeningHours }) => $hasOpeningHours && css`
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #d5d5d5;
    `}
`

const Title = styled.h1`
    font-weight: 600;
    font-size: 25px;
    color: ${({ theme }) => theme.colors.brand};
`

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Name = styled.div`
    color: ${({ theme }) => theme.colors.brand};
    font-weight: 600;
`

const DayWrapper = styled.div`
    display: flex;
`

const Day = styled.div`
    min-width: 115px;
    font-weight: 600;
    text-transform: capitalize;
`

const Time = styled.div`

`

const Text = styled.div`
    color: ${({ theme }) => theme.colors.brand};
    margin-top: 10px;
`

export {
    Wrapper,
    Title,
    InnerWrapper,
    Name,
    DayWrapper,
    Day,
    Time,
    Text
}