import {
    useEffect,
    useState
} from 'react'

import { useIsomorphicLayoutEffect } from '@gotamedia/fluffy'
import { useLoaderData } from '@remix-run/react'

import useEmbeddedRect from '~/hooks/useEmbeddedRect'

import FamilyAdPage from '~/components/Family/pages/FamilyAdPage'

import type { FamilyAdsPayload } from '~/modules/family/types'
import * as Styled from './style'
import type * as Types from './types'

const Memorial: Types.MemorialComponent = () => {
    const [isReady, setIsReady] = useState(false)

    const { rectRefSetter, trigger, revalidate } =  useEmbeddedRect()

    const { payload } = useLoaderData<{ payload: FamilyAdsPayload}>()

    const adItem = Array.isArray(payload.ads) && payload.ads[0] ? payload.ads[0] : null

    useIsomorphicLayoutEffect(() => {
        trigger({
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
            x: 0,
            y: 0
        })

        setIsReady(true)
    }, [trigger])

    useEffect(() => {
        if (isReady) {
            revalidate({ force: true })
        }
    }, [revalidate, isReady])
    
    return (
        <Styled.Wrapper ref={rectRefSetter}>
            <FamilyAdPage
                image={adItem?.main_image || ''}
                alt={adItem?.body || ''}
            />
        </Styled.Wrapper>
    )
}

export default Memorial