import useLazyRef from '@gotamedia/fluffy/useLazyRef'

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon
} from 'react-share'

import { isClient } from '~/utils/helpers'

import * as Styled from './style'
import type * as Types from './types'

const SocialMediaShare: Types.SocialMediaShareComponent = (props) => {
    const {
        facebook = true,
        twitter = true,
        linkedin = true,
        withHeader = true,
        ...DOM_PROPS
    } = props

    const url = useLazyRef(() => isClient ? location.href : '')

    return (
        <Styled.Wrapper {...DOM_PROPS}>
            {
                withHeader ? (
                    <Styled.Title>
                        {'Dela'}
                    </Styled.Title>
                ) : (
                    null
                )
            }

            <Styled.InnerWrapper>
                {
                    facebook && (
                        <FacebookShareButton url={url.current!}>
                            <FacebookIcon />
                        </FacebookShareButton>
                    )
                }

                {
                    twitter && (
                        <TwitterShareButton url={url.current!}>
                            <TwitterIcon />
                        </TwitterShareButton>
                    )
                }

                {
                    linkedin && (
                        <LinkedinShareButton url={url.current!}>
                            <LinkedinIcon />
                        </LinkedinShareButton>
                    )
                }
            </Styled.InnerWrapper>
        </Styled.Wrapper>
    )
}

export default SocialMediaShare