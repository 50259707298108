import styled from 'styled-components'
import Button from '@gotamedia/fluffy/Button'

const Link = styled.a`

`

const ApplyButton = styled(Button)`
    margin: 20px 0;
    width: 100%;
`

export {
    Link,
    ApplyButton
}