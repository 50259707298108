import styled from 'styled-components'

import ApplyButtonComponent from '../ApplyButton'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Link = styled.a`

`

const ApplyButton = styled(ApplyButtonComponent)`
    margin-bottom: 20px;
    width: 100%;
`

const Headline = styled.p`
    font-weight: 700;
    padding-top: 10px;
    margin-bottom: 5px;
    border-top: 1px solid #d5d5d5;

    &:first-child {
        border-top: 0;
    }
`

const Text = styled.p`
    margin-bottom: 10px;
`

const TextLink = styled.a`
    color: ${({ theme }) => theme.colors.brand};
    fill: ${({ theme }) => theme.colors.brand};
    display: flex;
    margin: 0;
    &:hover {
        text-decoration: underline;
    }

    span {
        margin: auto 10px auto 0;
    }

    &:last-of-type {
        margin-bottom: 10px;
    }
`

export {
    Wrapper,
    Link,
    ApplyButton,
    Headline,
    Text,
    TextLink
}