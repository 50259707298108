import { useLoaderData } from '@remix-run/react'

import Icon, {
    Icons,
    IconSizes
} from '@gotamedia/fluffy/Icon'

import getValidUrl from '~/utils/getValidUrl'

import * as Styled from './style'
import type * as Types from './types'
import type { CompanyPayload } from '~/modules/marketGuide/types'

const CompanyInfo: Types.CompanyInfoComponent = () => {
    const { payload } = useLoaderData<{ payload: CompanyPayload }>()

    const {
        websiteUrl,
        name,
        address,
        phone,
        email
    } = payload

    return (
        <Styled.Wrapper>
            <Styled.Title>
                {name}
            </Styled.Title>

            <Styled.Text>
                {address}
            </Styled.Text>

            {
                phone ? (
                    <Styled.Link href={`tel:${phone}`}>
                        <Icon
                            icon={Icons.Phone}
                            size={IconSizes.Small}
                        />

                        {phone}
                    </Styled.Link>
                ) : (
                    null
                )
            }

            {
                email ? (
                    <Styled.Link href={`mailto:${email}`}>
                        <Icon
                            icon={Icons.Envelope}
                            size={IconSizes.Small}
                        />

                        {email}
                    </Styled.Link>
                ) : (
                    null
                )
            }

            {
                websiteUrl ? (
                    <Styled.Link
                        href={getValidUrl(websiteUrl)}
                        target={'_blank'}
                    >
                        <Icon
                            icon={Icons.Link}
                            size={IconSizes.Small}
                        />

                        {'Hemsida'}
                    </Styled.Link>
                ) : (
                    null
                )
            }
            
        </Styled.Wrapper>
    )
}

export default CompanyInfo