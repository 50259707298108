import styled from 'styled-components'

const Content = styled.div`
    margin-bottom: 15px;

    a {
        color: ${({ theme }) => theme.colors.brand};
    }

    &:last-child {
        margin-bottom: 0;
    }
`

export {
    Content
}