import { useLoaderData } from '@remix-run/react'
import {
    CMPR,
    Vendors,
    getVendorFromPlainHTML
} from '@gotamedia/cmp'

import Video from '@gotamedia/fluffy/Video'

import * as Styled from './style'
import type * as Types from './types'
import type { JobPayload } from '~/modules/jobs/types'

const JobVideos: Types.JobVideosComponent = () => {
    const {
        payload: {
            videos
        }
    } = useLoaderData<{ payload: JobPayload }>()
    
    return (
        videos && videos.length ? (
            <Styled.Wrapper>
                {
                    videos.map(video => {
                        if (video.type === 'link') {
                            return (
                                <CMPR
                                    key={video.value}
                                    vendor={Vendors.BNYoutube}
                                >
                                    <Video
                                        width={'100%'}
                                        height={315}
                                        src={video.value}
                                    >
                                        <Video.Providers.YouTube />
                                    </Video>
                                </CMPR>
                            )
                        } else if (video.type === 'embed') {
                            const vendor = getVendorFromPlainHTML(video.value)

                            if (vendor) {
                                return (
                                    <CMPR
                                        vendor={vendor}
                                        key={video.value}
                                    >
                                        <Styled.Embedds dangerouslySetInnerHTML={{__html: video.value}} />
                                    </CMPR>
                                )
                            }

                            return null
                        } else {
                            return null
                        }
                    })
                }
            </Styled.Wrapper>
        ) : (
            null
        )
    )
}

export default JobVideos
