import { useLoaderData } from '@remix-run/react'

import useLazyRef from '@gotamedia/fluffy/useLazyRef'

import getWeekDayNames from '~/utils/getWeekDayNames'

import * as Styled from './style'
import type * as Types from './types'
import type { CompanyPayload } from '~/modules/marketGuide/types'

const CompanyOpeningHours: Types.CompanyOpeningHoursComponent = () => {
    const { payload: {
        openingHours
    } } = useLoaderData<{ payload: CompanyPayload }>()

    const sortedDays = useLazyRef(() => {
        const weekDays = getWeekDayNames()

        const items = Object.entries(weekDays).map(([key, value]) => {
            //@ts-ignore
            const values = openingHours[key]

            if (values.start && values.end) {
                return {
                    name: value,
                    value: values
                }
            } else {
                return null
            }
        }).filter(i => i) as {
            name: string,
            value: {
                start: string,
                end: string
            }
        }[]

        return items
    })

    const hasOpeningHours = sortedDays.current?.length ? true : false

    return (
        <Styled.Wrapper $hasOpeningHours={hasOpeningHours}>
            {
                hasOpeningHours ? (
                    <>
                        <Styled.Title>
                            {'Öppettider'}
                        </Styled.Title>

                        {
                            sortedDays.current?.map(({name, value}, idx) => {
                                if (value.start && value.end) {
                                    return (
                                        <Styled.InnerWrapper key={`${name}-${idx}`}>
                                            <Styled.DayWrapper>
                                                <Styled.Day>
                                                    {`${name}: `}
                                                </Styled.Day>
    
                                                <Styled.Time>
                                                    {`${value.start || ''} - ${value.end || ''}`}
                                                </Styled.Time>
                                            </Styled.DayWrapper>
                                        </Styled.InnerWrapper>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </>
                ) : (
                    null
                )
            }
        </Styled.Wrapper>
    )
}

export default CompanyOpeningHours