import { useLoaderData } from '@remix-run/react'

import useEmbeddedRect from '~/hooks/useEmbeddedRect'

import JobLogo from './components/JobLogo'
import JobContent from './components/JobContent'
import JobInfo from './components/JobInfo'
import JobVideos from './components/JobVideos'
import SocialMediaShare from '~/components/SocialMediaShare'

import * as Styled from './style'
import type * as Types from './types'
import type { JobPayload } from '~/modules/jobs/types'
import BackLink from '~/components/BackLink/BackLink'

const JobPage: Types.JobPageComponent = () => {
    const { rectRefSetter } =  useEmbeddedRect()

    const {payload} = useLoaderData<{ payload: JobPayload }>()

    return (
        <Styled.Wrapper ref={rectRefSetter}>
            {Object.keys(payload).length > 0 ? (
                <Styled.InnerWrapper>
                    <BackLink />
                    <JobLogo />

                    <Styled.Content>
                        <Styled.InnerContent>
                            <JobContent />
                        </Styled.InnerContent>

                        <Styled.SidebarContent>
                            <JobVideos />
                            <JobInfo />
                            <SocialMediaShare />
                        </Styled.SidebarContent>
                    </Styled.Content>
                </Styled.InnerWrapper>
            ) : null}
        </Styled.Wrapper>
    )
}

export default JobPage