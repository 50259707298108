import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.p`
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
`

const Content = styled.div`
    
`

const Info = styled.div`
    margin-top: 10px;
`

export {
    Wrapper,
    Title,
    Content,
    Info
}